var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-form-group",
    { attrs: { invalid: _vm.error !== null } },
    [
      _c(
        "gov-label",
        { staticClass: "govuk-!-font-weight-bold", attrs: { for: _vm.id } },
        [_vm._t("label", [_vm._v(_vm._s(_vm.label))])],
        2
      ),
      _vm._t("hint", [
        _vm.hint
          ? _c("gov-hint", {
              attrs: { for: _vm.id },
              domProps: { textContent: _vm._s(_vm.hint) }
            })
          : _vm._e()
      ]),
      _c("gov-file-upload", {
        ref: "file",
        attrs: {
          value: _vm.value,
          id: _vm.id,
          name: _vm.id,
          accept: _vm.accept
        },
        on: {
          change: function($event) {
            _vm.$emit("input", $event)
            _vm.removeExisting = false
          }
        }
      }),
      _vm._t("after-input"),
      _vm.image
        ? [
            _vm.existingUrl && !_vm.value && !_vm.removeExisting
              ? _c("img", {
                  key: "ExistingImage::" + _vm._uid,
                  attrs: { src: _vm.existingUrl, alt: "Organisation logo" }
                })
              : _vm.value
              ? _c("img", {
                  key: "UploadedImage::" + _vm._uid,
                  attrs: { src: _vm.value, alt: "Uploaded organisation logo" }
                })
              : _vm._e()
          ]
        : _vm._e(),
      _vm.error !== null
        ? _c("gov-error-message", {
            attrs: { for: _vm.id },
            domProps: { textContent: _vm._s(_vm.error) }
          })
        : _vm._e(),
      _vm._t("after-error-message"),
      (_vm.existingUrl && !_vm.removeExisting) || _vm.value
        ? _c(
            "div",
            { staticClass: "govuk-!-margin-top-2" },
            [
              _c(
                "gov-button",
                {
                  attrs: { type: "button", error: "" },
                  on: { click: _vm.onRemove }
                },
                [_vm._v("Remove file")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }